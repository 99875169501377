var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "b-form",
          [
            _vm.company && _vm.displayJurisdictionSelection
              ? [
                  _c("p", [
                    _c("span", [
                      _vm._v(
                        "\n            Your company's home state " +
                          _vm._s(_vm.filingTenseDisplayed) +
                          " "
                      ),
                      _c("strong", [_vm._v(_vm._s(_vm.jurisdictionDisplayed))]),
                      _vm._v(".\n          "),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: { href: "#", "aria-label": "change here link" },
                        on: { click: _vm.changeHomeStateHandler },
                      },
                      [_c("small", [_vm._v("Click here to change")])]
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm.jurisdictions.length > 1 ||
            (_vm.isFilingDba && _vm.allowFilingJurisdictionSelection)
              ? _c(
                  "b-form-group",
                  [
                    _c("template", { slot: "label" }, [
                      _vm._v("\n          Where would you like to make your "),
                      _c("strong", [_vm._v(_vm._s(_vm.humanizedFilingName))]),
                      _vm._v(" filing?\n        "),
                    ]),
                    _c("multi-select", {
                      attrs: {
                        multiple: _vm.isMultiJurisdiction,
                        placeholder: `Choose where to file ${_vm.humanizedFilingName}`,
                        options: _vm.jurisdictions,
                      },
                      on: {
                        select: _vm.filingJurisdictionSelected,
                        remove: _vm.removeByJurisdiction,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "singleLabel",
                            fn: function ({ option }) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(option) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3655528614
                      ),
                      model: {
                        value: _vm.jurisdictionSelection,
                        callback: function ($$v) {
                          _vm.jurisdictionSelection = $$v
                        },
                        expression: "jurisdictionSelection",
                      },
                    }),
                    _vm.isFilingDba && _vm.showJurisdictionDisclaimer
                      ? _c(
                          "b-card",
                          { staticClass: "mt-3 jurisdiction-disclaimer" },
                          [
                            _c("b-card-text", [
                              _c("em", [
                                _c("small", [
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.jurisdictionDisclaimer)),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.localOptions.length && _vm.isFilingDba
                      ? _c(
                          "div",
                          { staticClass: "mt-3" },
                          [
                            _c("multi-select", {
                              attrs: {
                                multiple: true,
                                placeholder: `Choose the cities / counties to file ${_vm.humanizedFilingName}`,
                                options: _vm.localOptions,
                                "group-values": "local",
                                "group-label": "state",
                                "track-by": "name",
                                label: "name",
                                "group-select": false,
                              },
                              on: {
                                select: _vm.filingLocalJurisdictionSelected,
                                remove: _vm.removeByLocalJurisdiction,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "singleLabel",
                                    fn: function ({ option }) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(option) +
                                            "\n            "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                700496166
                              ),
                              model: {
                                value: _vm.localJurisdictionSelection,
                                callback: function ($$v) {
                                  _vm.localJurisdictionSelection = $$v
                                },
                                expression: "localJurisdictionSelection",
                              },
                            }),
                            _c("small", [
                              _vm._v(
                                "* If your city/county is not listed in the dropdown above, please email us so we can determine if it can be added and facilitate the filing process for you."
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ],
          2
        ),
        _vm.ineligibleToOrderProduct(_vm.product) && !_vm.loading
          ? _c(
              "div",
              { staticClass: "ineligible-product" },
              [
                _c("p", { staticClass: "ineligible-product-inner" }, [
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.ineligibleToOrderText) +
                        "\n        "
                    ),
                  ]),
                ]),
                _c(
                  "b-button",
                  {
                    staticStyle: { "min-width": "130px", float: "right" },
                    attrs: { variant: "primary", "aria-label": "back button" },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal()
                      },
                    },
                  },
                  [_vm._v("\n        Back\n      ")]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "b-list-group",
                  _vm._l(
                    _vm.iJurisdictionSelection,
                    function (jurisdiction, index) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: index,
                          staticClass: "flex-column align-items-start",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex w-100 justify-content-between",
                            },
                            [
                              _c("h3", { staticClass: "mb-1" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(jurisdiction) +
                                    " — " +
                                    _vm._s(_vm.product.name) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _vm.hasFilingInCart && _vm.nonHireUsRoute
                            ? _c("div", { staticClass: "mt-4 mb-4" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n              Looks like you already have a Formation Filing in your cart.\n            "
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "\n              Remove the Formation Filing from the cart in order to select a different one.\n            "
                                  ),
                                ]),
                              ])
                            : _vm.hasActiveFiling
                            ? _c("div", { staticClass: "mt-4 mb-4" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n              There is already an active Formation Filing on your account.\n            "
                                  ),
                                ]),
                              ])
                            : _c("div", [
                                !_vm.isLocal[jurisdiction]
                                  ? _c("div", [
                                      _c("small", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("dollars")(
                                                _vm.ourFee(jurisdiction)
                                              )
                                            ) +
                                            " - Our fee\n              "
                                        ),
                                      ]),
                                      _c("br"),
                                    ])
                                  : _vm._e(),
                                _vm.dbaAddOns[jurisdiction]?.publicationCost > 0
                                  ? _c("div", [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dollars")(
                                              _vm.dbaAddOns[jurisdiction]
                                                .publicationCost
                                            )
                                          ) +
                                            " - " +
                                            _vm._s(
                                              _vm.dbaAddOns[jurisdiction]
                                                .methodName
                                            ) +
                                            " Publication Filing"
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.dbaAddOns[jurisdiction]?.cogsCost > 0
                                  ? _c("div", [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dollars")(
                                              _vm.dbaAddOns[jurisdiction]
                                                .cogsCost
                                            )
                                          ) +
                                            " - " +
                                            _vm._s(
                                              _vm.dbaAddOns[jurisdiction]
                                                .methodName
                                            ) +
                                            " Certificate Of Good Standing Filing"
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.freeFilingProcessingFee(jurisdiction)
                                  ? _c("div", [
                                      _c("small", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dollars")(
                                                _vm.freeFilingProcessingFee(
                                                  jurisdiction
                                                )
                                              )
                                            ) +
                                            " - Credit Card Processing Fee"
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.raCost(_vm.product, jurisdiction)
                                  ? _c("div", [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dollars")(
                                              _vm.raCost(
                                                _vm.product,
                                                jurisdiction
                                              )
                                            )
                                          ) + " - Registered agent service"
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.shouldIncludeRenewalService(
                                  _vm.product,
                                  jurisdiction
                                )
                                  ? _c("div", [
                                      _c("small", [
                                        _vm._v(
                                          "$" +
                                            _vm._s(_vm.renewalCost()) +
                                            " - Renewal Service"
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.boiEnabled
                                  ? _c("div", [
                                      _c("small", [
                                        _vm._v(
                                          "$" +
                                            _vm._s(_vm.boiCost()) +
                                            " - Beneficial Ownership Information Report"
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.isLocal[jurisdiction] && _vm.isFilingDba
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mb-3 border-bottom pt-3 pb-3",
                                        },
                                        _vm._l(
                                          _vm.iLocalJurisdictionSelected,
                                          function (localJurisdiction) {
                                            return _c(
                                              "div",
                                              { key: localJurisdiction.name },
                                              [
                                                localJurisdiction.state ===
                                                jurisdiction
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "font-weight-bold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  localJurisdiction.name
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isLocal[
                                                          jurisdiction
                                                        ]
                                                          ? _c("div", [
                                                              _c("small", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "dollars"
                                                                    )(
                                                                      _vm.ourFee(
                                                                        localJurisdiction
                                                                      )
                                                                    )
                                                                  ) +
                                                                    " - Our fee"
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.dbaAddOns[
                                                          localJurisdiction.name
                                                        ]?.publicationCost > 0
                                                          ? _c("div", [
                                                              _c("small", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "dollars"
                                                                    )(
                                                                      _vm
                                                                        .dbaAddOns[
                                                                        localJurisdiction
                                                                          .name
                                                                      ]
                                                                        .publicationCost
                                                                    )
                                                                  ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .dbaAddOns[
                                                                        localJurisdiction
                                                                          .name
                                                                      ]
                                                                        .methodName
                                                                    ) +
                                                                    " Publication Filing"
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.dbaAddOns[
                                                          localJurisdiction.name
                                                        ]?.cogsCost > 0
                                                          ? _c("div", [
                                                              _c("small", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "dollars"
                                                                    )(
                                                                      _vm
                                                                        .dbaAddOns[
                                                                        localJurisdiction
                                                                          .name
                                                                      ].cogsCost
                                                                    )
                                                                  ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .dbaAddOns[
                                                                        localJurisdiction
                                                                          .name
                                                                      ]
                                                                        .methodName
                                                                    ) +
                                                                    " Certificate Of Good Standing Filing"
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._l(
                                                          _vm.filingMethodsForLocalJurisdiction(
                                                            localJurisdiction
                                                          ),
                                                          function (method) {
                                                            return _c(
                                                              "b-form-radio",
                                                              {
                                                                key: method.id,
                                                                staticClass:
                                                                  "ml-3",
                                                                attrs: {
                                                                  name: localJurisdiction.name,
                                                                  value: method,
                                                                  disabled:
                                                                    _vm.localAddedToSaved(
                                                                      localJurisdiction.name
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .filingMethodSelection[
                                                                      localJurisdiction
                                                                        .name
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.filingMethodSelection,
                                                                        localJurisdiction.name,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "filingMethodSelection[localJurisdiction.name]",
                                                                },
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "dollars"
                                                                        )(
                                                                          method.cost
                                                                        )
                                                                      ) +
                                                                      " — " +
                                                                      _vm._s(
                                                                        method.name
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        method.filing_description
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                ]),
                                                                _c("small", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "filingMethodTimingNoticeText"
                                                                      )(method)
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mb-3 border-bottom pt-3 pb-3",
                                      },
                                      [
                                        _vm.monitoringServiceIsAvailable
                                          ? _c(
                                              "b-checkbox",
                                              {
                                                on: {
                                                  change:
                                                    _vm.monitoringProductSelected,
                                                },
                                                model: {
                                                  value:
                                                    _vm.monitoringServiceSelected,
                                                  callback: function ($$v) {
                                                    _vm.monitoringServiceSelected =
                                                      $$v
                                                  },
                                                  expression:
                                                    "monitoringServiceSelected",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.monitoringProductPricingInfo()
                                                    ) +
                                                    "\n                "
                                                ),
                                                _c("fa-icon", {
                                                  directives: [
                                                    {
                                                      name: "b-popover",
                                                      rawName:
                                                        "v-b-popover.hover.top",
                                                      value:
                                                        _vm.toolTipDataForMonitoring,
                                                      expression:
                                                        "toolTipDataForMonitoring",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass:
                                                    "question-circle pl-1",
                                                  attrs: {
                                                    icon: "question-circle",
                                                    size: "xs",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          _vm.filingMethodsForJurisdiction(
                                            jurisdiction
                                          ),
                                          function (method) {
                                            return _c(
                                              "b-form-radio",
                                              {
                                                key: method.id,
                                                attrs: {
                                                  name: jurisdiction,
                                                  value: method,
                                                  disabled:
                                                    _vm.addedToSaved(
                                                      jurisdiction
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.filingMethodSelection[
                                                      jurisdiction
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.filingMethodSelection,
                                                      jurisdiction,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filingMethodSelection[jurisdiction]",
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm._f("dollars")(
                                                          method.cost
                                                        )
                                                      ) +
                                                      " — " +
                                                      _vm._s(method.name) +
                                                      " " +
                                                      _vm._s(
                                                        method.filing_description
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]),
                                                _c("small", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "filingMethodTimingNoticeText"
                                                      )(method)
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "lower-container",
                                    class: {
                                      "lower-container-single-item":
                                        !_vm.containsJurisdiction(jurisdiction),
                                    },
                                  },
                                  [
                                    _vm.containsJurisdiction(jurisdiction)
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "lower-container-text",
                                          },
                                          [
                                            _vm._v(
                                              "\n                Total cost for " +
                                                _vm._s(jurisdiction) +
                                                " " +
                                                _vm._s(
                                                  _vm.product.filing_name
                                                ) +
                                                " filing:\n                " +
                                                _vm._s(
                                                  _vm._f("dollars")(
                                                    _vm.totalCost(
                                                      _vm.product,
                                                      jurisdiction
                                                    )
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isLocal[jurisdiction]
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "lower-container-btn",
                                          },
                                          [
                                            _vm.groupAddedToSave(jurisdiction)
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: {
                                                      variant: "danger",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeById(
                                                          jurisdiction
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  Remove All\n                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.addedToSaved(jurisdiction)
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticStyle: {
                                                      "min-width": "130px",
                                                    },
                                                    attrs: {
                                                      variant: "primary",
                                                      disabled:
                                                        !_vm.containsJurisdiction(
                                                          jurisdiction
                                                        ) ||
                                                        _vm.isStateLoading(
                                                          _vm.loading,
                                                          jurisdiction
                                                        ),
                                                      "aria-label":
                                                        _vm.submissionLabel +
                                                        " button",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.submitDecision(
                                                          _vm.product,
                                                          jurisdiction
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    !_vm.isStateLoading(
                                                      _vm.loading,
                                                      jurisdiction,
                                                      index
                                                    )
                                                      ? _c("div", [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.submissionLabel
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ])
                                                      : _c("b-spinner", {
                                                          attrs: {
                                                            small: "",
                                                            variant: "light",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "lower-container-btn",
                                          },
                                          [
                                            _vm.addedToSaved(jurisdiction)
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant: "danger",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeById(
                                                          jurisdiction
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  Remove\n                "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "b-button",
                                                  {
                                                    staticStyle: {
                                                      "min-width": "130px",
                                                    },
                                                    attrs: {
                                                      variant: "primary",
                                                      disabled:
                                                        !_vm.containsJurisdiction(
                                                          jurisdiction
                                                        ) ||
                                                        _vm.isStateLoading(
                                                          _vm.loading,
                                                          jurisdiction
                                                        ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.submitDecision(
                                                          _vm.product,
                                                          jurisdiction
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    !_vm.isStateLoading(
                                                      _vm.loading,
                                                      jurisdiction,
                                                      index
                                                    )
                                                      ? _c("div", [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.submissionLabel
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ])
                                                      : _c("b-spinner", {
                                                          attrs: {
                                                            small: "",
                                                            variant: "light",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        ),
                                  ]
                                ),
                              ]),
                        ]
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
        _c("company-domestic-registration-modal", {
          ref: "company-domestic-registration-modal",
          attrs: { company: _vm.company, "load-products": false },
          on: {
            "domestic-registration-changed": _vm.domesticRegistrationChanged,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }